<template>

	<div id="app">

			<router-view />
		
	</div>

</template>

