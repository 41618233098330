import { defineStore } from 'pinia';

export const useDateStore = defineStore('date', {

	state: () => ({
		fixedDate: new Date('2024-08-15'),
	}),

	getters: {
		daysPassed(state) {
			const oneDay = 24 * 60 * 60 * 1000; // milliseconds per day
			const today = new Date();
			const diffInMs = today - state.fixedDate;
			return Math.floor(diffInMs / oneDay);
		},
	},
	
});