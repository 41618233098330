import { createApp } from 'vue';
import { createPinia } from 'pinia';


import App from './App.vue';
import router from './router'; // Import the router
import VueGtag from 'vue-gtag';
import './firebase'; // Import the Firebase configuration

import './assets/main.css';

const app = createApp(App);
const pinia = createPinia();



app.use(router); // Use the router
app.use(VueGtag, { config: { id: 'G-2L0ZEB56HL' } });
app.use(pinia);

app.directive('focus', { mounted(el) { el.focus(); } });

app.mount('#app');