<template>
	<div :style="{ height: height + 'px' }" class="divider"></div>
</template>

<script>
	export default {
		props: {
			height: {
				type: Number,
				required: true
			}
		}
	};
</script>

<style scoped>
	.divider {
		width: 100%;
		background-color: transparent;
		/* Change if you want a visible divider */
	}
</style>