<template>


	<div class="todo-item" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">
		
		<div style="display: flex; align-items: baseline; justify-content: space-between;">	
			
			
			
			<div style="display: flex; align-items: baseline; justify-content: space-between;">

				<input 
					type="checkbox" 
					:checked="todo.done" 
					@change="toggleTodoDone(day, todo)">

				<span 
					v-if="!todo.editing" 
					:class="{ 'done': todo.done }">
					{{ todo.todo }}
				</span>
				
				<input 
					v-else 
					type="text" 
					v-model="todo.todo"
					@keyup.enter="editTodo(day, todo, todo.todo); 
					todo.editing = false" 
					class="edit-input"
					:style="{ 
						backgroundColor: todo.color || selectedColor, 
						color: getInvertedColor(todo.color) 
					}">
			</div>

			
			<div style="display: flex; flex-direction: row-reverse;">

				<button 
					v-if="!todo.editing" 
					@click="todo.editing = true">✏️
				</button>

				<button 
					v-else 
					@click="editTodo(day, todo, todo.todo); 
					todo.editing = false">✅
				</button>

				<button 
					v-if="todo.editing" 
					@click="deleteTodo(day, todo)">❌
				</button>

			</div>

			
			
		</div>

	</div>


</template>

<script>
	export default {
		props: {
			todo: Object,
			day: Date,
			toggleTodoDone: Function,
			editTodo: Function,
			deleteTodo: Function,
			getInvertedColor: Function,
			selectedColor: String
		}
	};
</script>

<style scoped>

</style>