<template>
	<div class="top">
		<div class="left">
			<button id="thisWeekButton" @click="resetWeek" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Today</button>
			<button id="thisWeekButton" @click="prevWeek" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Prev</button>
			<button id="thisWeekButton" @click="nextWeek" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Next</button>
			
			<div v-if="showRightDiv">

				<button v-if="viewMode !== 'weekReport'" @click="viewWeekReport" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">View Week Report</button>
				<button v-else @click="returnToWeekView" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Return to Week View</button>
				
			</div>
			
			
		</div>
		<div v-if="!showRightDiv" class="right"></div>
			<h3  class="day-number" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">
			{{ currentMonthYear }}
		</h3>
		
		
		<div class="right" v-if="showRightDiv">
			
			<ThemeSwitcher @theme-changed="updateColors" />
			
			<div class="example full">
				<input type="text" class="coloris" id="colorPicker1" value="#ffffff">
				<input type="text" class="coloris" id="colorPicker2" value="#000000">
			</div>
		</div>
	</div>
</template>

<script>
import ThemeSwitcher from "@/components/ThemeSwitcher.vue"

	export default {
		props: {
			selectedColor: String,
			selectedColor2: String,
			currentMonthYear: String,
			getInvertedColor: Function,
			viewMode: String
		},
		data() {
			return {
				showRightDiv: process.env.VUE_APP_SHOW_RIGHT_DIV === 'true'
			};
		},
		methods: {
			resetWeek() {
				this.$emit('reset-week');
			},
			prevWeek() {
				this.$emit('prev-week');
			},
			nextWeek() {
				this.$emit('next-week');
			},

	
			viewWeekReport() {
				this.$emit('view-week-report');
			},
			returnToWeekView() {
				this.$emit('return-to-week-view');
			},
			updateColors(backgroundColor, textColor) {
				this.$emit('update-colors', backgroundColor, textColor); // Emit the new colors
			},
		},
		components: {
			ThemeSwitcher
		},
	};
</script>

<style scoped>
	/* Add any specific styles for TopBar here */
</style>