<template>
	<div class="theme-switcher">
		<button v-for="theme in themes" :key="theme.color" class="theme-button"
			:class="{ active: activeTheme.color === theme.color }"
			:style="{ backgroundColor: theme.backgroundColor }" @click="switchTheme(theme)">
			<span class="theme-circle" :style="{ backgroundColor: theme.textColor }"></span>
		</button>
	</div>
</template>

<script setup>
	import {
		ref,
		defineEmits,
		onMounted
	} from 'vue';

	const emit = defineEmits(['theme-changed']);

	const themes = [{
			color: 'QW01',
			backgroundColor: '#F3F3E9',
			textColor: '#ffffff'
		},
		{
			color: 'QW02',
			backgroundColor: '#353533',
			textColor: '#F3F3E9'
		},
		{
			color: 'QW03',
			backgroundColor: '#F9CD26',
			textColor: '#353533'
		},
		{
			color: 'QW04',
			backgroundColor: '#F3F3E9',
			textColor: '#353533'
		},
		{
			color: 'QW05',
			backgroundColor: '#353533',
			textColor: '#8AEB7A'
		},
		{
			color: 'QW06',
			backgroundColor: '#F3F3E9',
			textColor: '#205FB2'
		},
		{
			color: 'QW07',
			backgroundColor: '#205FB2',
			textColor: '#FFFFFF'
		},
		{
			color: 'QW08',
			backgroundColor: '#353533',
			textColor: '#F9CD26'
		},
		{
			color: 'QW09',
			backgroundColor: '#FFFFFF',
			textColor: '#2B9E18'
		},
	];

	// Initialize activeTheme with a placeholder that will be replaced onMounted
	const activeTheme = ref({});

	const switchTheme = (theme) => {
		activeTheme.value = theme;
		document.body.style.backgroundColor = theme.backgroundColor;
		document.body.style.color = theme.textColor;
		emit('theme-changed', theme.backgroundColor, theme.textColor);
		localStorage.setItem('activeThemeColor', theme.color); // Corrected to save just the color code
	};

	onMounted(() => {
		const savedThemeColor = localStorage.getItem('activeThemeColor');
		if (savedThemeColor) {
			const theme = themes.find(t => t.color === savedThemeColor);
			if (theme) {
				activeTheme.value = theme;
				document.body.style.backgroundColor = theme.backgroundColor;
				document.body.style.color = theme.textColor;
			}
		} else {
			switchTheme(themes[0]); // Default to the first theme if none is saved
		}
	});
</script>




<style scoped>
    /* Existing styles */
    .theme-switcher {
        display: flex;
        gap: 7px;
    }

    .theme-button {
        width: 14px;
        height: 28px;
        border: 2px solid #000;
        border-radius: 7px;
        cursor: pointer;
        position: relative;
        transition: transform 0.3s ease;
        display: flex; /* Ensure contents are centered */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
    }

    .theme-button.active {
        transform: translateY(0px);
    }

    /* New styles for the circle */
    .theme-circle {
        width: 7px; /* Circle size */
        height: 7px; /* Circle size */
        border-radius: 50%; /* Make it round */
        position: absolute; /* Position inside the button */
        bottom: 2px; /* Default position down */
        transition: bottom 0.3s ease; /* Smooth transition */
	   /* border: 2px solid #000; */
    }

    .theme-button.active .theme-circle {
        bottom: 13px; /* Move up when active */
    }
</style>