<template>



			
		
			<div id="calendar" :style="{ backgroundColor: selectedColor, color: '#000000' || getInvertedColor(selectedColor) }" class="calendar">
				
				<div class="home-links" style="display: flex; align-items: center; justify-content: space-between;">

					<router-link to="/" class="home-link" style="display: flex; align-items: center;">
						<svg width="35px" height="35px" viewBox="0 0 513 513" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_54_1120)">
								<path fill-rule="evenodd" clip-rule="evenodd" 
									d="M385.188 128.584L320.733 128.584L193.038 128.584L128.582 128.584H128.58L128.58 383.975H193.036L193.036 250.578H320.732V383.975H385.188L385.188 128.584ZM0.885942 0.280122V0.279785L512.885 0.279808V128.584H512.884L512.884 512.279L0.884788 512.279L0.884766 0.280122L0.885942 0.280122Z" 
									:fill="selectedColor2"/>
							</g>
							<defs>
								<clipPath id="clip0_54_1120">
								<rect width="512" height="512" fill="white" transform="translate(0.884766 0.279785)"/>
								</clipPath>
							</defs>
						</svg>
						<span style="margin-left: 14px;" :style="{ color: getInvertedColor(selectedColor) }">Quikweek</span> <!-- Added text next to the logo -->
					</router-link>

					<div v-if="showLogin">
						<div v-if="isLoggedIn">

							<router-link to="/" class="home-link" style="display: flex; align-items: center;">
								
								
								<img v-if="user.photoURL" :src="user.photoURL" alt="User Photo" width="35px" height="35px">
							</router-link>
							
						</div>

						<div v-else>
							<router-link to="/login" class="article-link" style="background-color: var(--color-orange);">Get Started</router-link>
						</div>
					</div>

				</div>


				<Divider :height="280" />

				<div v-if="isLoggedIn">
					<!-- <p :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">Hi {{ user.displayName.split(' ')[0] }} </p> -->
				</div>
				
				<div v-else>
					<p :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">Welcome to Quikweek </p>
				</div>
				
				

				
				<div class="explanation-container">
					<textarea 
					v-model="headline.userHeadline" 
					@input="saveHeadline"
					class="headline-textarea"
					placeholder="Plan your week, visualise what's ahead."
					:style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }"
					
					></textarea>
				</div>
				
				
				

				<TopBar
					:selectedColor="selectedColor"
					:selectedColor2="selectedColor2"
					:currentMonthYear="currentMonthYear"
					:getInvertedColor="getInvertedColor"
					:viewMode="viewMode"
					@reset-week="resetWeek"
					@prev-week="prevWeek"
					@next-week="nextWeek"
					
					
					@view-week-report="viewMode = 'weekReport'"
					@return-to-week-view="viewMode = 'week'"
					@update-colors="(bgColor, textColor) => { selectedColor = bgColor; selectedColor2 = textColor; }" 
				/>
				
				<div id="weekView" v-if="viewMode === 'week'">
				<!-- Week view content -->

					<div id="weekDays">

						<div class="day-container" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">

							<div class="day-number">Week {{ currentWeek }}</div>
							<div class="day-description" v-if="showWeekDescription">
								<textarea 
									v-model="currentWeekDescription" 
									placeholder="Add week description" 
									@keyup.enter="saveWeekDescription" 
									class="week-description-textarea"
									:style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor)}"
								></textarea>
							</div>

						</div>

						<div v-for="day in currentWeekDays" :key="day.getTime()" 
							class="day-container" 
							:class="{ 'current-day': day.getTime() === currentDay.getTime() }" 
							:style="{ backgroundColor: day.getTime() === currentDay.getTime() ? selectedColor2 : '', color: day.getTime() === currentDay.getTime() ? getInvertedColor(selectedColor2) : getInvertedColor(selectedColor) }" 
							@click="addTodoInput(day)"
							@mouseover="hoveredDay = day"
							@mouseleave="hoveredDay = null">

							<div class="dayss">
								<div class="day-number">{{ day.getDate() }}</div>
								<button class="mini-link" v-if="showDayButton" @click="showDayView(day)" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">Open</button>
								<div class="day-number">{{ day.toLocaleDateString("en-US", { weekday: "short" }) }}</div>
							</div>

							<TodoItem
								v-for="todo in getTodosForCurrentDay(day)"
								:key="todo.day.getTime()"
								:todo="todo"
								:day="day"
								:toggleTodoDone="toggleTodoDone"
								:editTodo="editTodo"
								:deleteTodo="deleteTodo"
								:getInvertedColor="getInvertedColor"
								:selectedColor="day.getTime() === currentDay.getTime() ? selectedColor2 : selectedColor"
							/>

							<div v-if="day === currentDayInput" class="input-container">
								<input type="text" v-model="todoText" :placeholder="'Add todo @ ' + day.getDate() + ' ' +(day.toLocaleDateString('en-US', { weekday: 'short' }))" @keyup.enter="saveTodo" v-focus :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">
							</div>

							<div v-if="hoveredDay === day && currentDayInput !== day" class="hover-message">
								Click to add a todo
							</div>

						</div>

					</div>
				</div>

				<div id="dayView" v-if="viewMode === 'day'">

					<div class="day-container" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">
						<!-- Displaying the selected day's number and weekday -->
						<div class="dayss">
							<div class="day-number">{{ selectedDay.getDate() }}</div>
							<button @click="viewMode = 'week'" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Return to Week View</button>
							<div class="day-number">{{ selectedDay.toLocaleDateString("en-US", { weekday: "short" }) }}</div>
						</div>

						<!-- New input container for adding todos in day view -->
						<div class="input-container">
							<input type="text" v-model="todoText"
								:placeholder="'Add todo @ ' + selectedDay.getDate() + ' ' +(selectedDay.toLocaleDateString('en-US', { weekday: 'short' }))"
								@keyup.enter="saveTodo" v-focus
								:style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">
						</div>

						<div class="todo-columns">
							<div class="todo-column">
								<h3>Incomplete</h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(selectedDay).filter(todo => !todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="selectedDay" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>

							<div class="todo-column">
								<h3>Completed</h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(selectedDay).filter(todo => todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="selectedDay" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>
						</div>

						<Divider :height="280" />

						<div class="todo-columns">
							
							<div class="todo-columns">
								<h1 style="border-bottom: 1px solid black; width: 100%;">Yesterday</h1>
								
							</div>
							<div class="todo-columns">
								<h1 style="border-bottom: 1px solid black;"> Tomorrow </h1>
							</div>		
						</div>
						
						<div class="todo-columns" style="gap: 4%;">
							<div class="todo-column">
								<div class="dayss" style="border-bottom: 1px solid black; width: 100%;">
									<h3>
										{{ new Date(selectedDay.getTime() - 86400000).getDate() }} 
									</h3> 
									
									<h3>
										{{ new Date(selectedDay.getTime() - 86400000).toLocaleDateString("en-US", { weekday: "long" }) }}
									</h3>
								</div>
							</div>
							<div class="todo-column">
								<div class="dayss" style="border-bottom: 1px solid black; width: 100%;">
									<h3>
										{{ new Date(selectedDay.getTime() + 86400000).getDate() }} 
									</h3> 
									
									<h3>
										{{ new Date(selectedDay.getTime() + 86400000).toLocaleDateString("en-US", { weekday: "long" }) }}
									</h3>
								</div>

							</div>
						</div>

						
						<div class="todo-columns" style="gap: 4%;">
							<div class="todo-column">
								
								<h3>Incomplete </h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(new Date(selectedDay.getTime() - 86400000)).filter(todo => !todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="new Date(selectedDay.getTime() - 86400000)" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>
							
							<div class="todo-column">
								<h3>Completed</h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(new Date(selectedDay.getTime() - 86400000)).filter(todo => todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="new Date(selectedDay.getTime() - 86400000)" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>
								
							<div class="todo-column">
								
								<h3>Incomplete</h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(new Date(selectedDay.getTime() + 86400000)).filter(todo => !todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="new Date(selectedDay.getTime() + 86400000)" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>
								
							<div class="todo-column">
								<h3>Completed</h3>
								<TodoItem v-for="todo in getTodosForCurrentDay(new Date(selectedDay.getTime() + 86400000)).filter(todo => todo.done)"
									:key="todo.day.getTime()" :todo="todo" :day="new Date(selectedDay.getTime() + 86400000)" :toggleTodoDone="toggleTodoDone"
									:editTodo="editTodo" :deleteTodo="deleteTodo" :getInvertedColor="getInvertedColor"
									:selectedColor="selectedColor" />
							</div>
						</div>
										

					</div>
					
				</div>

				<div id="weekReport" v-if="viewMode === 'weekReport'">
					<div class="day-container" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">

						<div class="day-number">Week {{ currentWeek }}</div>

						<div class="day-description" v-if="showWeekDescription">
							<textarea 
								v-model="currentWeekDescription" 
								placeholder="Add week description"
								@keyup.enter="saveWeekDescription" 
								class="week-description-textarea"
								:style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor)}">
							</textarea>
						</div>

						<Divider :height="70" />
						
						<h1>This week you added a total of {{ totalTasks }} todos. <br> That's {{ totalTasks ? ((completedTasks / totalTasks) * 100).toFixed(2) : 0 }}% completion rate.</h1>
						
						<Divider :height="140" />


						<div>

							<!-- <p>Days in Current Week: {{ currentWeekDays.map(day => day.toLocaleDateString()).join(', ') }}</p> -->


							<!-- Visualization with circles -->
							
							<div class="todo-columns">
								<div class="todo-column">

									<h1>{{ incompleteTasks }} incomplete</h1>
								</div>
								<div class="todo-column">

									<h1>{{ completedTasks }} completed</h1>
								</div>

							</div>
							<div class="todo-columns">
								<div class="incomplete-tasks">
									<div v-for="n in incompleteTasks" :key="'incomplete-' + n"
										class="task-circle incomplete-circle" :style="{ backgroundColor: selectedColor2 }"></div>
								</div>
								<div class="completed-tasks">
									<div v-for="n in completedTasks" :key="'completed-' + n"
										class="task-circle completed-circle"></div>
								</div>
							</div>

							

							

							<div v-for="day in currentWeekDays" :key="day.getTime()" class="day-report">
								<div class="divider" style="height: 70px;"></div>
								<h1 style="border-bottom: 1px solid black;">
									{{ day.getDate() }} — {{ day.toLocaleDateString("en-US", { weekday: "long" }) }}
								</h1>
								
								<div class="todo-columns">
									<div class="todo-column">
										<h3>Incomplete</h3>
										<div v-if="getTodosForCurrentDay(day).some(todo => !todo.done)">
											<TodoItem v-for="todo in getTodosForCurrentDay(day).filter(todo => !todo.done)"
												:key="todo.day.getTime()" :todo="todo" :day="day"
												:toggleTodoDone="toggleTodoDone" :editTodo="editTodo" :deleteTodo="deleteTodo"
												:getInvertedColor="getInvertedColor" :selectedColor="selectedColor" />
										</div>
										<div v-else></div> <!-- Empty state for incomplete -->
									</div>
									<div class="todo-column">
										<h3>Completed</h3>
										<div v-if="getTodosForCurrentDay(day).some(todo => todo.done)">
											<TodoItem v-for="todo in getTodosForCurrentDay(day).filter(todo => todo.done)"
												:key="todo.day.getTime()" :todo="todo" :day="day"
												:toggleTodoDone="toggleTodoDone" :editTodo="editTodo" :deleteTodo="deleteTodo"
												:getInvertedColor="getInvertedColor" :selectedColor="selectedColor" />
										</div>
										<div v-else></div> <!-- Empty state for completed -->
									</div>
								</div>
							</div>

							<Divider :height="140" />

							<!-- New section for task summary -->
							<div class="task-summary">
							<div class="task-bar">
								<div class="completed-bar" :style="{ width: completedPercentage + '%' }"></div>
								<div class="incomplete-bar" :style="{ width: incompletePercentage + '%' }"></div>
							</div>
							<h2>You have completed {{ completedTasks }} todos, and have {{ incompleteTasks }} incomplete tasks.</h2>
							<!-- New percentage of completed tasks out of total -->
							<h3>Completion Rate: {{ totalTasks ? ((completedTasks / totalTasks) * 100).toFixed(2) : 0 }}%</h3>
						</div>


						</div>
					</div>
				</div>

				<BottomBar
					:selectedColor="selectedColor"
					:selectedColor2="selectedColor2"
					:showSavedMessage="showSavedMessage"
					:getInvertedColor="getInvertedColor"
					:isLoggedIn="isLoggedIn"
					@clear-local-storage="clearLocalStorage"
					@import-todos="importTodos"
					@export-todos="exportTodosAsJson"
					@save-to-local-storage="saveToLocalStorage"
					@logout="logout"
				/>

				
				
				
				
				<div v-if="!isLoggedIn" class="landing-page" :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor)}">
					
					
					<h1 class="headline">Basic Features</h1>
					
					
					<Divider :height="14" />


					<div class="features-grid">
						<!-- <div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">
								<span style="font-size: 3em;">⚡</span> 
								<h3>Magic Headline</h3> 
							</div>

		
							
							<p>Go ahead, edit headline. Type in the Goal you're chasing and let it remind you anytime you open Quikweek.</p>
						</div> -->
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">
								<span style="font-size: 3em;">⚡</span> 
								<h3>Lightning—Fast</h3> 
							</div>

		
							
							<p>No lag, no waiting — just pure productivity.</p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">💸</span> 
								<h3>Free to use</h3> 
							</div>
							<p>Start planning immediately, without any cost or commitment. </p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">🗓️</span> 
								<h3>At-a-Glance Weekly View</h3> 
							</div>
							<p>Visualize your entire week in a sleek grid format. Navigate between weeks with next/prev controls. </p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">
								<span style="font-size: 3em;">✅</span> 
								<h3>Get things—Done</h3> 

							</div>
							<p>Add, complete, edit, or remove tasks with ease. Your week, your way.</p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">💾</span>  
								<h3>Automatic Local Saving</h3> 
							</div>
							<p>Your plans are stored in your browser, just remember to hit "Save" from time to time. </p>
						</div>
						
						
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">🚫</span>  
								<h3>Distraction-Free Design</h3> 
							</div>
							<p>A clean, minimal UI keeps you focused on what matters mosts — your tasks and goals. </p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">💻</span> 
								<h3>Desktop-Optimized</h3> 
							</div>
							<p>While mobile-friendly, Quikweek shines on larger screens.  </p>
						</div>
						<div class="features-column" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2)}">
							<div style="padding-top: 14px;">

								<span style="font-size: 3em;">🚀</span> 
								<h3>Instant Access</h3> 
							</div>
							<p>Jump right in – no sign-up required.  </p>
						</div>
						
						
					</div>

					<Divider :height="70" />
					<h1 class="headline">Posts</h1>
					
					
					<Divider :height="14" />

					<div style="display: flex; flex-direction: column; align-items: flex-start; gap: 7px;"> 
						<button @click="goToPost3" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }"> 
							Week 35 — Update
						</button>
						
						<button @click="goToPost2" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }"> 
							Clicky Buttons and a hidden surprise!
						</button>
						
						<button @click="goToPost1" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }"> 
							Introducing Quikweek
						</button>  
					</div>
					
				</div>

			</div>
	





</template>

<script>

	import { computed } from 'vue';
	import tinycolor from "tinycolor2";
	import "@melloware/coloris/dist/coloris.css";
	import { coloris, init } from "@melloware/coloris";
	import { VueGtag } from 'vue-gtag'; // Import gtag directly

	import TopBar from './TopBar.vue'; // Correct path
	import BottomBar from './BottomBar.vue'; // Correct path
	import TodoItem from "./TodoItem.vue";
	import Divider from './Divider.vue';

	import { auth } from '../firebase';
	import { signOut, onAuthStateChanged } from "firebase/auth";
	import { ref, onMounted } from 'vue';


	import { format, startOfWeek, addDays, isSameDay, parseISO } from 'date-fns';

	init();




	export default {

		setup() {
			const isLoggedIn = ref(false);
			const user = ref({}); // Reactive object to hold user data

			// Set up an observer on the Auth object
			onMounted(() => {
				onAuthStateChanged(auth, (firebaseUser) => {
				if (firebaseUser) {
					isLoggedIn.value = true;
					user.value = {
					uid: firebaseUser.uid,
					email: firebaseUser.email,
					displayName: firebaseUser.displayName,
					photoURL: firebaseUser.photoURL,
					};
				} else {
					isLoggedIn.value = false;
					user.value = {}; // Clear user data when logged out
				}
				});
			});

			const logout = async () => {
				try {
				await signOut(auth);
				isLoggedIn.value = false; // Update login state
				user.value = {}; // Clear user data
				} catch (error) {
				console.error("Error during logout: ", error);
				}
			};

			return { isLoggedIn, user, logout };
		},



		data() {

			return {

				currentDate: new Date(),
				currentWeekDays: [],
				currentMonthYear: '',
				currentDay: new Date(),
				currentDayInput: null,
				currentWeekDescription: '',
				weekDescriptions: {},
				todoText: '',
				todos: {},
				currentWeek: null,
				dataIsLoaded: false,
				showSavedMessage: false,
				selectedColor: null,
				selectedColor2: null,
				none: null,
				viewMode: 'week', // 'week' or 'day'
        			selectedDay: null,
				showDayButton: process.env.VUE_APP_SHOW_DAY_BUTTON === 'true',
				showColorPicker: process.env.VUE_APP_SHOW_TODO_COLOR_PICKER === 'true',
				showWeekDescription: process.env.VUE_APP_SHOW_WEEK_DESCRIPTION === 'true',
				showLogin: process.env.VUE_APP_SHOW_LOGIN === 'true',
				hoveredDay: null,
				
				headline: {
					userHeadline: ''
				}

				

			};

		},

		components: { TopBar, BottomBar, TodoItem, Divider },

		methods: {

			updateCalendar() {

				// ("updateCalendar RUN")

				const startOfWeek = new Date(this.currentDate);
				startOfWeek.setDate(this.currentDate.getDate() - this.currentDate.getDay() + 1);
				// startOfWeek.setHours(0, 0, 0, 0);
				
				// console.log("startOfWeek: ", startOfWeek)

				this.currentWeekDays = Array.from({ length: 7 }, (_, i) => {
					const day = new Date(startOfWeek);
					day.setDate(startOfWeek.getDate() + i);
					return day;
				});

				// ("this.currentWeekDays: ", this.currentWeekDays)

				this.currentMonthYear = this.currentWeekDays[0].toLocaleString("en-US", {
					month: "long",
					year: "numeric"
				});

				this.currentWeekDays.forEach(day => {
					const currentDayKey = day.toISOString().split('T')[0];
					if (!this.todos[currentDayKey]) {
						this.todos[currentDayKey] = [];
					}
				});

				const currentDate = this.currentDate
				const startDate = new Date(currentDate.getFullYear(), 0, 1);
				const days = Math.floor((currentDate - startDate) / 86400000); // 1 day = 86,400,000 milliseconds
				const currentWeek = Math.ceil((days + 1) / 7); // Adding 1 to account for the current day
				this.currentWeek = currentWeek;
				this.loadWeekDescription();

			},


			// START TopBar.vue methods

				resetWeek() {
					this.saveWeekDescription();
					this.currentDate = new Date();
					this.currentDate.setDate(this.currentDate.getDate() - 1);
					this.currentDay = new Date();
					this.updateCalendar();

				},

				prevWeek() {
					this.saveWeekDescription(); // Save current week description before changing
					this.currentDate.setDate(this.currentDate.getDate() - 7);
					this.updateCalendar();
				},

				nextWeek() {
					this.saveWeekDescription(); // Save current week description before changing
					this.currentDate.setDate(this.currentDate.getDate() + 7);
					this.updateCalendar();
				},
				
				
			

			// END TopBar.vue methods



			// START BottomBar.vue methods

				clearLocalStorage() {
					localStorage.clear(); // Clear the localStorage
					this.todos = {}; // Clear the todos object
					this.weekDescriptions = {}; // Clear all week descriptions
					this.updateCalendar();
				},

				importTodos(event) {
					const file = event.target.files[0];
					const reader = new FileReader();

					reader.onload = (e) => {
					const contents = e.target.result;
					let importedData;

					try {
						importedData = JSON.parse(contents);
					} catch (error) {
						console.error('Error parsing JSON file:', error);
						return;
					}

					if (typeof importedData === 'object' && !Array.isArray(importedData)) {
						this.todos = importedData.todos;


						// Update weekDescriptions to use the new format
						Object.keys(importedData.weekDescriptions).forEach((key) => {
							const [year, week] = key.split('_week_');
							const newKey = `${year}_week_${week}`;
							this.weekDescriptions[newKey] = importedData.weekDescriptions[key];
						});
						
						this.headline = importedData.headline || { userHeadline: '' };
						this.currentHeadline = this.weekDescriptions[`week_${this.currentWeek}`]?.headline || '';

						Object.keys(this.todos).forEach((dateKey) => {
						this.todos[dateKey].forEach((todo) => {
						todo.day = new Date(todo.day);
						});
						});

						this.loadWeekDescription();
						this.selectedColor = importedData.selectedColor;
						this.selectedColor2 = importedData.selectedColor2;
						this.updateColorPickers(this.selectedColor, this.selectedColor2);
					} else {
						console.error('Invalid JSON file format');
					}
					};

					reader.readAsText(file);
				},

				exportTodosAsJson() {

					const exportData = {
						todos: this.todos,
						selectedColor: this.selectedColor,
						selectedColor2: this.selectedColor2,
						weekDescriptions: this.weekDescriptions,
						headline: this.headline
					};

					const jsonData = JSON.stringify(exportData);
					const blob = new Blob([jsonData], { type: 'application/json' });
					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = 'todos.json';
					link.click();
					URL.revokeObjectURL(url);
				},

				saveToLocalStorage(showMessage = true) {

					localStorage.setItem('todos', JSON.stringify(this.todos));
					localStorage.setItem('selectedColor', this.selectedColor);
					localStorage.setItem('selectedColor2', this.selectedColor2);
					localStorage.setItem('weekDescriptions', JSON.stringify(this.weekDescriptions));
					localStorage.setItem('headline', JSON.stringify(this.headline));
					localStorage.setItem('activeTheme', this.selectedColor);

					if (showMessage) {
						this.showSavedMessage = true;
						setTimeout(() => { this.showSavedMessage = false; }, 2000);
					}

					gtag('event', 'save', {
					event_category: 'Button',
					event_label: 'Save Button Click',
					value: 1
					});
				},


			// END BottomBar.vue methods

			addTodoInput(day) {

				this.currentDayInput = day;
				// Listen for the Escape key press event
				document.addEventListener('keyup', (event) => {
					if (event.key === 'Escape') {
						this.clearTodoInput();
					}
				});

			},

			getTodosForCurrentDay(day) {
				const currentDayKey = day.toISOString().split('T')[0];
				const todos = this.todos[currentDayKey] || [];
				return todos
			},

			saveTodo() {

				// Ensure currentDayInput is set when in day view
				if (this.viewMode === 'day') {
					this.currentDayInput = this.selectedDay;
				}

				const currentDayKey = this.currentDayInput.toISOString().split('T')[0];

				if (!this.todos[currentDayKey]) {
					this.todos[currentDayKey] = [];
				}
				this.todos[currentDayKey].push({
					day: this.currentDayInput,
					todo: this.todoText,
					color: this.selectedColor,
					color2: this.selectedColor2,

					date: new Date().toLocaleDateString(),
					time: new Date().toLocaleTimeString(),
					done: false,
					doneDate: null,
				});

				// console.log(this.todos)
				this.todoText = '';
				this.currentDayInput = null;

			},

			undoTodo(day, todo) {
				todo.done = false; // Set the 'done' property to false
				todo.doneDate = null; // Reset the 'doneDate' property to null
			},

			editTodo(day, todo, newText) {
				todo.todo = newText;
			},

			deleteTodo(day, todo) {
				const currentDayKey = day.toISOString().split('T')[0];
				const todos = this.todos[currentDayKey];
				const index = todos.indexOf(todo);
				if (index > -1) {
					todos.splice(index, 1);
					todo.deletedDate = new Date().toLocaleString();
				}
			},
			
			clearTodoInput() {
				this.currentDayInput = null;
				this.todoText = '';
			},
			
			toggleTodoDone(day, todo) {
				todo.done = !todo.done; // Toggle the 'done' property
				if (todo.done) {
					todo.doneDate = new Date()
				.toLocaleString(); // Set the 'doneDate' property if the todo is marked as done
				} else {
					todo.doneDate = null; // Reset the 'doneDate' property if the todo is marked as undone
				}
			},

			updateColor(todo) {

				if (todo && todo.style) {
					// Update the background color of the new div element
					const colorPreview = document.querySelector('.color-preview');
					colorPreview.style.backgroundColor = todo.color;
				}

			},

			updateColorPickers(color1, color2) {
				// Update the value of color pickers to reflect the selected theme colors
				const colorPicker1 = document.getElementById('colorPicker1');
				const colorPicker2 = document.getElementById('colorPicker2');
				if (colorPicker1 && colorPicker2) {
					colorPicker1.value = color1;
					colorPicker2.value = color2;
				}
			},

			getInvertedColor(color) {
				const readableColor = tinycolor.mostReadable(color, ['#000', '#fff'], {
					includeFallbackColors: true
				}).toHexString();
				return readableColor;
			},

			showDayView(day) {
				this.viewMode = 'day';
				this.selectedDay = day;
			},

			saveWeekDescription() {
				const currentYear = this.currentDate.getFullYear();
				const weekNumber = this.currentWeek; // Assuming this is already calculated
				const weekKey = `${currentYear}_week_${weekNumber}`;

				this.weekDescriptions[weekKey] = this.currentWeekDescription;
				// Save to local storage or any other persistent storage if needed
			},

			loadWeekDescription() {
				const currentYear = this.currentDate.getFullYear();
				const weekNumber = this.currentWeek; // Assuming this is already calculated
				const weekKey = `${currentYear}_week_${weekNumber}`;

				this.currentWeekDescription = this.weekDescriptions[weekKey] || '';
			},

			    // Add a new method for the Save button
			saveButtonClicked() {
       		 	this.saveToLocalStorage(true); // Explicitly show message when Save button is clicked
    			},

			getTodosForCurrentWeek() {
				const todosForWeek = [];
				this.currentWeekDays.forEach(day => {
					const dayKey = day.toISOString().split('T')[0];
					if (this.todos[dayKey]) {
						this.todos[dayKey].forEach(todo => {
						todosForWeek.push(todo);
						});
					}
				});
				return todosForWeek;
			},

			saveHeadline() {
				this.saveToLocalStorage(false);
			},

			loadHeadline() {
				const savedHeadline = localStorage.getItem('headline');
				if (savedHeadline) {
				this.headline = JSON.parse(savedHeadline);
				}
			},

			goToPost1() {
				this.$router.push({ name: 'Post1' });
			},
			goToPost2() {
				this.$router.push({ name: 'Post2' });
			},
			goToPost3() {
				this.$router.push({ name: 'Post3' });
			},

			

		},

		created() {

			// console.log("Created")
			this.selectedColor = localStorage.getItem('selectedColor') || "#F3F3E9";
			this.selectedColor2 = localStorage.getItem('selectedColor2') || "white";

			const savedActiveTheme = localStorage.getItem('activeTheme');
			if (savedActiveTheme) {
				this.selectedColor = savedActiveTheme; // Load active theme
			}

			const savedWeekDescriptions = localStorage.getItem('weekDescriptions');

			if (savedWeekDescriptions) {
				this.weekDescriptions = JSON.parse(savedWeekDescriptions);
				// console.log("this.weekDescriptions: ", this.weekDescriptions)
			}

			const savedTodos = localStorage.getItem('todos');

			// console.log("savedTodos: ", JSON.parse(savedTodos))

			if (savedTodos) {

				try {
					const importedTodos = JSON.parse(savedTodos);

					if (typeof importedTodos === 'object' && !Array.isArray(importedTodos)) {
						
						Object.keys(importedTodos).forEach((dateKey) => {

							const todos = importedTodos[dateKey];

							todos.forEach((todo) => {
								todo.day = new Date(todo.day);
							});

						});
						this.todos = importedTodos;

					} 
					
					else { console.error('Invalid JSON file format'); }

				} 
				
				catch (error) { console.error('Error parsing saved data:', error); }

			}

			this.loadWeekDescription();
			this.loadHeadline();
		},


		mounted() {

			this.updateCalendar();
			this.resetWeek()

			// console.log("Mounted")

			// Existing Coloris initialization
			coloris({
				el: ".coloris",
				onChange: (color, el) => {
				// Update the selectedColor or selectedColor2 based on which input triggered the change
				if (el.id === 'colorPicker1') {
					this.selectedColor = color;
				} else if (el.id === 'colorPicker2') {
					this.selectedColor2 = color;
				}
			}
			});
			coloris({
				theme: 'pill',
				themeMode: 'dark',
				formatToggle: true,
				closeButton: false,
				clearButton: false,
				swatches: [
					'#FFFFFF',
					'#F3F3E9',
					'#caf0f8',
					'#f5ebe0',
					'#f9dcc4',
					'#fff3b0',

					'#353533',
					'#DD3A2E',
					'#205FB2',
					'#F9CD26',
					'#2B9E18',
					'#FF6400',
				]
			});


			// Add event listener for ESC key to return to week view
			document.addEventListener('keyup', this.handleKeyUp);


			// Additional setup or logic

			// console.log("MOUNTED TODOS:", this.todos)
			// Check if there is saved data in local storage
			const savedTodos = localStorage.getItem('todos');


			if (savedTodos) {
				try {
					const importedTodos = JSON.parse(savedTodos);
					if (typeof importedTodos === 'object' && !Array.isArray(importedTodos)) {

						Object.keys(importedTodos).forEach((dateKey) => {
							const todos = importedTodos[dateKey];
							todos.forEach((todo) => {
								todo.day = new Date(todo.day);
							});
						});

						this.todos = importedTodos;

					} else {
						console.error('Invalid JSON file format');
					}

				} catch (error) {
					console.error('Error parsing xd data:', error);
				}
			}

		},

		computed: {
			totalTasks() {
				// console.log("TOTAL TASKS:", this.getTodosForCurrentWeek().length)
				return this.getTodosForCurrentWeek().length;
			},
			completedTasks() {
				return this.getTodosForCurrentWeek().filter(todo => todo.done).length;
			},
			incompleteTasks() {
				return this.totalTasks - this.completedTasks;
			},
			completedPercentage() {
				return this.totalTasks ? (this.completedTasks / this.totalTasks) * 100 : 0;
			},
			incompletePercentage() {
				return this.totalTasks ? (this.incompleteTasks / this.totalTasks) * 100 : 0;
			}
		}

		



	};
</script>

<style scoped>
/* Add this CSS to your component's style section */
.edit-input {
    word-break: break-all; /* Break words at arbitrary points */
    overflow-wrap: break-word; /* Break long words */
    width: 100%; /* Ensure the input takes full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.done {
    word-break: break-all; /* Break words at arbitrary points */
    overflow-wrap: break-word; /* Break long words */
}
</style>