<template>



	<div id="calendar" :style="{ backgroundColor: '#f3f3e9', color: '#000000' }" class="calendar">

		<router-link to="/" class="home-link" style="display: flex; align-items: center;">
			<img src="../assets/Quikweek-logo.svg" alt="" srcset="" width="35px" height="35px">
			<span style="margin-left: 14px;">Quikweek</span> <!-- Added text next to the logo -->
		</router-link>


		<div style="height: 280px" class="divider"></div>

		<div :style="{ backgroundColor: '#f3f3e9', color: '#000000' }">

			Quikweek v1.0.17
			
			<h1>Week 35 — Update</h1>

			<div style="height: 28px" class="divider"></div>

			<div class="explanation-container">
				<h2>This week was somehow intense, but felt less productive overall..</h2>
			</div>

			<a class="author-widget" href="https://x.com/itsdailyin" target="_blank" rel="noopener noreferrer"
				style="text-decoration: none; color: inherit; margin-bottom: 28px">
				<img src="../assets/PP.jpg" alt="Author Image" class="author-image" />
				<div class="author-info">
					<div class="author-name">Author: Tom</div>
					<div class="author-name">Published: Sep 01 2024</div>
				</div>
			</a>

			
			
			
			
			
			<div style="height: 70px" class="divider"></div>
			
			
			<blockquote>
				I've been busy with some client work and personal stuff this week, but managed to work on Quikweek a bit.
			</blockquote>

			<div style="height: 14px" class="divider"></div>
			<p>Let's dive in!</p>
			<h2>Here's what's new:</h2>
			
			
			<ul>
				<li><strong>Quikweek is still free</strong> <br/>The goal is to always have a free version of Quikweek, like it is now.<br/> Been thinking about adding member accounts for extra benefits. <br/>Sync with mobile might be the first obvious thing.</li>
				<li><strong>Day blocks are more divided now.</strong> <br/>The features block turned out okay, so I applied the same idea to the week view. <br/>Feedback is welcome.</li>
				<img src="../assets/post-image/3/quikweek-column-gap.png" alt="Quikweek v1.0" class="article-image">
				<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
					Before / After — Day Cards now have more space
				</figcaption>
				<img src="../assets/post-image/3/quikweek-column-gap-before-after.png" alt="Quikweek v1.0" class="article-image">
				<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
					Before / After — Day Cards now have more space — zoom out view.
				</figcaption>
				<li><strong>The Features block wasn't great on mobile.</strong> <br/>It's fixed now.</li>
				<li><strong>Added a hover function to add todos on day text based on this feedback from X:</strong> <br/></li>
				<blockquote class="twitter-tweet">
					<p lang="en" dir="ltr">
						hey <a href="https://twitter.com/KacperOchmanski?ref_src=twsrc%5Etfw">@KacperOchmanski</a>, just a quick heads up — based on your request — I&#39;ve added mouse hover indicator ⚡️<br><br>Thanks for the feedback, feels much better now! <a href="https://twitter.com/hashtag/buildinpublic?src=hash&amp;ref_src=twsrc%5Etfw">#buildinpublic</a> <a href="https://t.co/ZQp1c6dhny">pic.twitter.com/ZQp1c6dhny</a>
					</p>
					&mdash; Tom 🟢 (@itsdailyin) <a href="https://twitter.com/itsdailyin/status/1829866027783766410?ref_src=twsrc%5Etfw">August 31, 2024</a>
				</blockquote>
				<li>Now, when you hover over a day block, you’ll see a mouse hover indicator with a message “Click to add a todo”. <br/>Thanks <a href="https://twitter.com/KacperOchmanski">@KacperOchmanski</a> for the tip!</li>
			</ul>
			
			<div style="height: 14px" class="divider"></div>
			
			
			
			<h2>That's it for this week. </h2>

			<p></p>

			<p>Let me know if you have any thoughts or feedback on these changes.</p>

			<p>Oh, and before you go — I’ve been busy this week adding and testing one new feature I think you’re going to love…</p>

				






			
			
			
		

			<h2>Happy planning!</h2>

		</div>

		<div style="height: 140px" class="divider"></div>

		



		<div class="footer">
			<div class="left-column">
				<div v-if="$route.path !== '/'">
					<button @click="$router.push('/')" class="article-link" style="background-color: #Ffffff; color: #353533;">Back to Home</button>
				</div>
			</div>
			<div class="right-column">
				<span>Quikweek v1.0.{{ daysPassed }}</span>
			</div>
		</div>




	</div>


</template>

<script>

import { useDateStore } from '@/stores/dateStore';

export default {
	setup() {
		const dateStore = useDateStore();
		return {
			daysPassed: dateStore.daysPassed,
		};
	},
	name: 'Post3',
	mounted() {
		const script = document.createElement('script');
		script.src = "https://platform.twitter.com/widgets.js";
		script.async = true;
		script.charset = "utf-8";
		document.body.appendChild(script);
	},
};
</script>

