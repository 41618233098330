<template>



	<div id="calendar" :style="{ backgroundColor: '#f3f3e9', color: '#000000' }" class="calendar">

		<router-link to="/" class="home-link" style="display: flex; align-items: center;">
			<img src="../assets/Quikweek-logo.svg" alt="" srcset="" width="35px" height="35px">
			<span style="margin-left: 14px;">Quikweek</span> <!-- Added text next to the logo -->
		</router-link>


		<div style="height: 280px" class="divider"></div>

		<div :style="{ backgroundColor: '#f3f3e9', color: '#000000' }">

			Quikweek v1.0.3
			
			<h1>Introducing Quikweek</h1>
			<div style="height: 28px" class="divider"></div>


			<div class="explanation-container">
				<h2>Ever feel overwhelmed by your to-do list? <br> Or lose track of tasks in a busy week?</h2>
				<h2>I've been there.</h2>
			</div>


			<a class="author-widget" href="https://x.com/itsdailyin" target="_blank" rel="noopener noreferrer"
				style="text-decoration: none; color: inherit; margin-bottom: 28px">
				<img src="../assets/PP.jpg" alt="Author Image" class="author-image" />
				<div class="author-info">
					<div class="author-name">Author: Tom</div>
					<div class="author-name">Published: Aug 18 2024</div>
				</div>
			</a>

			<div style="height: 70px" class="divider"></div>


			<div class="explanation-container">


				<h2>That's why I made Quikweek — a personal space designed for organizing weekly tasks and visualizing what's ahead.</h2>

			</div>


			<p>Welcome to Quikweek v1.0 👋</p>
			<img src="../assets/post-image/1/quikweek.v1.0.webp" alt="Quikweek v1.0" class="article-image">

			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Quikweek v1.0 - App UI screenshot. This is the first public version of a new app I've been working
				on for the
				past few weeks / months...
			</figcaption>


			<div style="height: 35px" class="divider"></div>



			<ul>
				<li>Some might say:</li>
			</ul>
			<blockquote>
				Not much to see, is there...?
			</blockquote>

			<ul>
				<li>Well... yes. </li>
				<li>And no.</li>
			</ul>
			<div style="height: 35px" class="divider"></div>

			<p>Let me explain.</p>
			<div class="explanation-container">
				<h2>My goal was to launch an MVP (Minimum Viable Product) to get to market as quickly as possible,
					see how the
					community responds, and improve upon that.</h2>
			</div>



			<div style="height: 35px" class="divider"></div>

			<ul>
				<li>Let&#39;s see what ChatGPT has to say about Quikweek</li>
			</ul>
			<blockquote> Take a look at this app UI and describe what you see </blockquote>

			<img src="../assets/post-image/1/chatgpt-describe-app-ui.png" alt="Quikweek v1.0" class="article-image" >

			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Third party feedback.
			</figcaption>

			<div style="height: 35px" class="divider"></div>

			<h2>Here&#39;s the full response</h2>
			<blockquote>
				The image appears to be a screenshot of a simple calendar or scheduling application UI, named
				"Quikweek v1.0".
				Here's a detailed description of the UI:
			</blockquote>

			<blockquote>

				<ol>
					<li>
						<p><strong>Header Section:</strong></p>
						<ul>
							<li>The header displays navigation controls including a &quot;Quikweek&quot; button
								on the left
								side, which might be for returning to the main or home view.</li>
							<li>There are two arrow buttons (&quot;&lt;&quot; and &quot;&gt;&quot;) for
								navigating between
								weeks or months.</li>
							<li>The current month and year, &quot;August 2024&quot;, is displayed prominently in
								the
								center.</li>
						</ul>
					</li>
					<li>
						<p><strong>Calendar Layout:</strong></p>
						<ul>
							<li>The calendar is organized in a grid format, representing a week-by-week view of
								the month
								of August 2024.</li>
							<li>It starts with Week 33 on the left, which corresponds to the week starting with
								Monday,
								August 12th.</li>
							<li>The days of the week (Mon, Tue, Wed, etc.) are labeled along the top row of the
								calendar.
							</li>
							<li>Each day has an empty space, likely intended for adding events or notes.</li>
						</ul>
					</li>
					<li>
						<p><strong>Individual Days:</strong></p>
						<ul>
							<li>The days of the week from August 12th (Monday) to August 18th (Sunday) are
								visible in the
								grid.</li>
							<li>The grid is mostly blank, indicating that no events or tasks have been added to
								the
								calendar for those days.</li>
						</ul>
					</li>
					<li>
						<p><strong>Footer Section:</strong></p>
						<ul>
							<li>A footer section is visible at the bottom left corner with the text
								&quot;Quikweek
								v1.0&quot;, indicating the version of the app.</li>
							<li>A &quot;Save&quot; button is located at the bottom right corner, suggesting that
								users can
								save any changes or inputs they make to the calendar.</li>
						</ul>
					</li>
				</ol>
			</blockquote>

			<blockquote>
				This UI design appears to be minimalist, focused on functionality for scheduling or planning a week
				within a
				month.
			</blockquote>

			<div style="height: 35px" class="divider"></div>

			<p>Got it quite on point, to be honest!</p>
			<p>There&#39;s more to the story on how and why I made Quikweek, but let&#39;s leave that for another
				time.</p>
			<div style="height: 35px" class="divider"></div>


			<h2>What makes Quikweek special?</h2>
			<ul>
				<li><strong>Lightning Fast</strong>: It’s fast, like really fast. No waiting, no fuss.</li>
				<li><strong>Easy-to-use</strong>: Simply click on a day, add a to-do, hit Enter. ✅ Done.</li>
				<li><strong>All essentials are here</strong>: Add new to-dos, mark as done, edit, or delete. </li>
				<li><strong>No sign-up required</strong>: Your data stays in your browser. Just hit &quot;Save&quot;
					from time
					to time.</li>
				<li><strong>Made for Desktop</strong>: It&#39;s mobile-friendly but (at this point) optimized for
					larger
					screens.</li>
				<li><strong>In the moment</strong>: Focused on the present week / day for better planning.</li>
				<li><strong>Minimal UI</strong>: Helps you stay focused.</li>
				<li><strong>Free</strong>: Quikweek v1.0 is completely free to use.</li>
			</ul>
			<div style="height: 35px" class="divider"></div>

			<div class="explanation-container">
				<h2>Quikweek is not merely a to-do app nor a calendar app; it’s a blend of both. </h2>
			</div>



			<div style="height: 35px" class="divider"></div>

			<div class="explanation-container">
				<h2>If you've made it this far, thank you! </h2>
				<h2>I invite you to give Quikweek a try, share your thoughts, and help shape its evolution.
				</h2>
			</div>

			<p>Ready to get organized? </p>
			
			


		</div>

		<div style="height: 140px" class="divider"></div>
		

		

		<div class="footer">
			<div class="left-column">
				<div v-if="$route.path !== '/'">
					<button @click="$router.push('/')" class="article-link" style="background-color: #Ffffff; color: #353533;">Back to Home</button>
				</div>
			</div>
			<div class="right-column">
				<span>Quikweek v1.0.{{ daysPassed }}</span>
			</div>
		</div>


	

		




	</div>


</template>

<script>

import { useDateStore } from '@/stores/dateStore';

export default {
	setup() {
		const dateStore = useDateStore();
		return {
			daysPassed: dateStore.daysPassed,
		};
	},
	name: 'Posta1',
};

</script>

<style scoped>
	/* Add any specific styles for NewPage here */
</style>