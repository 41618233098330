import { createRouter, createWebHistory } from 'vue-router';

import Week from '../components/Week.vue';

import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';

import Post1 from '../post/Post1.vue';
import Post2 from '../post/Post2.vue';
import Post3 from '../post/Post3.vue';


const routes = [

	{
		path: '/',
		name: 'Home',
		component: Week,
		meta: {
			title: 'Quikweek — Ready to get organized?',
			ogTitle: 'Quikweek — Ready to get organized?',
			metaTags: [{
				name: 'description',
				content: "Plan your week, visualise what's ahead."
			}]
		}
	},

	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Login - Quikweek',
			ogTitle: 'Login to Quikweek',
			metaTags: [{
				name: 'description',
				content: "Login to start planning your week with Quikweek."
			}]
		}
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup,
		meta: {
			title: 'Signup - Quikweek',
			ogTitle: 'Signup for Quikweek',
			metaTags: [{
				name: 'description',
				content: "Signup to begin your journey with Quikweek."
			}]
		}
	},

	{
		path: '/post/introducing-quikweek',
		name: 'Post1',
		component: Post1,
		meta: {
			title: 'Post — Introducing Quikweek',
			ogTitle: 'Post — Introducing Quikweek',
			metaTags: [{
				name: 'description',
				content: "My goal was to launch an MVP (Minimum Viable Product) to get to market as quickly as possible, see how the community responds, and improve upon that."
			}]
		}
	},

	{
		path: '/post/clicky-buttons',
		name: 'Post2',
		component: Post2,
		meta: {
			title: 'Post — Clicky Buttons and a Hidden Surprise!			',
			ogTitle: 'Clicky Buttons and a Hidden Surprise!			',
			metaTags: [{
				name: 'description',
				content: "I've been tinkering away on some exciting updates to make your planning experience even smoother. Let me walk you through what's new.				"
			}]
		}
	},

	{
		path: '/post/quikweek-week-35-update',
		name: 'Post3',
		component: Post3,
		meta: {
			title: 'Post — Week 35 — Update',
			ogTitle: 'Week 35 — Update',
			metaTags: [{
				name: 'description',
				content: "This week was somehow intense, but felt less productive overall.."
			}]
		}
	},



];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			top: 0
		};
	}
});



router.beforeEach((to, from, next) => {

	document.title = to.meta.title || 'Quikweek';

	const metaTags = document.querySelectorAll('meta[name]');
	metaTags.forEach(tag => tag.remove());


	if (to.meta.metaTags) {
		to.meta.metaTags.forEach(tag => {
			const meta = document.createElement('meta');
			Object.keys(tag).forEach(key => {
				meta.setAttribute(key, tag[key]);
			});
			document.head.appendChild(meta);
		});
	}


	const ogTitle = document.querySelector('meta[property="og:title"]');
	if (ogTitle) {
		ogTitle.setAttribute('content', to.meta.ogTitle || 'Quikweek');
	} else {
		// If the meta tag doesn't exist, create it
		const newOgTitle = document.createElement('meta');
		newOgTitle.setAttribute('property', 'og:title');
		newOgTitle.setAttribute('content', to.meta.ogTitle || 'Quikweek');
		document.head.appendChild(newOgTitle);
	}

	next();
});

export default router;