<template>
	<div id="calendar" :style="{ backgroundColor: '#f3f3e9', color: '#000000',  minHeight: '100vh' }" class="calendar">
	  <h1>Signup</h1>
	  <button @click="signupWithGoogle">Signup with Google</button>
	  <button v-if="isLoggedIn" @click="logout">Logout</button>
	</div>
</template>

<script>
import { auth, provider } from '../firebase';
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
	setup() {
	  const router = useRouter();
	  const isLoggedIn = ref(false);

	  onMounted(() => {
	    onAuthStateChanged(auth, (user) => {
		 isLoggedIn.value = !!user; // Update login state
	    });
	  });

	  const signupWithGoogle = async () => {
	    try {
		 await signInWithPopup(auth, provider);
		 router.push('/'); // Redirect to home after signup
	    } catch (error) {
		 console.error("Error during signup: ", error);
	    }
	  };

	  const logout = async () => {
	    try {
		 await signOut(auth);
		 isLoggedIn.value = false; // Update login state
	    } catch (error) {
		 console.error("Error during logout: ", error);
	    }
	  };

	  return { signupWithGoogle, logout, isLoggedIn };
	}
}
</script>