<template>
	<div class="bottom">
		<div class="left">
			<div v-if="showLogin">

				<button v-if="isLoggedIn" @click="logout" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }">Logout</button>
			</div>
			<div class="left" v-if="showBottomDiv">
				<label for="importFile" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }" style="cursor: pointer;"> Import <input type="file" id="importFile" @change="importTodos" style="display:none;" /></label>
				<button id="exportButton" @click="exportTodosAsJson" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }" style="cursor: pointer;">Export</button>
				<button @click="clearLocalStorage" class="article-link" :style="{ backgroundColor: selectedColor2, color: getInvertedColor(selectedColor2) }" style="cursor: pointer;">Delete all data</button>
			</div>
			<span :style="{ backgroundColor: selectedColor, color: getInvertedColor(selectedColor) }">Quikweek v1.0.{{ daysPassed }}</span>
		</div>
		<div class="right" style="display: flex; flex-direction: row; align-items: center;">
			<span v-if="showSavedMessage" :style="{ backgroundColor: none, color: getInvertedColor(selectedColor), fontSize: '0.8rem' }"> Saved</span>
			<button @click="saveToLocalStorage" class="article-link" style="background-color: var(--color-orange);">Save</button>
		</div>
	</div>
</template>


<script>
	export default {
		props: {
			selectedColor: String,
			selectedColor2: String,
			showSavedMessage: Boolean,
			getInvertedColor: Function,
			isLoggedIn: Boolean
		},
		data() {
			return {
				showBottomDiv: process.env.VUE_APP_SHOW_BOTTOM_DIV === 'true',
				showLogin: process.env.VUE_APP_SHOW_LOGIN === 'true',
				fixedDate: new Date('2024-08-15'),
				none: null // Define 'none' here
			};
		},
		emits: ['clear-local-storage', 'import-todos', 'export-todos', 'save-to-local-storage',
		'logout'], // Declare emitted events
		computed: {
			formattedFixedDate() {
				return new Date(this.fixedDate).toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					weekday: 'long'
				});
			},
			// Move daysPassed calculation here
			daysPassed() {
				const oneDay = 24 * 60 * 60 * 1000; // milliseconds per day
				const today = new Date();
				const diffInMs = today - new Date(this.fixedDate);
				return Math.floor(diffInMs / oneDay);
			}
		},
		methods: {
			clearLocalStorage() {
				this.$emit('clear-local-storage');
			},
			importTodos(event) {
				this.$emit('import-todos', event);
			},
			exportTodosAsJson() {
				this.$emit('export-todos');
			},
			saveToLocalStorage() {
				this.$emit('save-to-local-storage');
			},
			goToPosts() {
				this.$router.push('/posts');
			},
			logout() {
				this.$emit('logout'); // Emit logout event
			}

		},


	};
</script>

<style scoped>

</style>