<template>



	<div id="calendar" :style="{ backgroundColor: '#f3f3e9', color: '#000000' }" class="calendar">

		<router-link to="/" class="home-link" style="display: flex; align-items: center;">
			<img src="../assets/Quikweek-logo.svg" alt="" srcset="" width="35px" height="35px">
			<span style="margin-left: 14px;">Quikweek</span> <!-- Added text next to the logo -->
		</router-link>


		<div style="height: 280px" class="divider"></div>

		<div :style="{ backgroundColor: '#f3f3e9', color: '#000000' }">

			Quikweek v1.0.8
			
			<h1>Clicky Buttons and a Hidden Surprise!</h1>

			<div style="height: 28px" class="divider"></div>

			<div class="explanation-container">
				<h2>I've been tinkering away on some exciting updates to make your planning experience even smoother. Let me walk you through what's new.</h2>
			</div>

			<a class="author-widget" href="https://x.com/itsdailyin" target="_blank" rel="noopener noreferrer"
				style="text-decoration: none; color: inherit; margin-bottom: 28px">
				<img src="../assets/PP.jpg" alt="Author Image" class="author-image" />
				<div class="author-info">
					<div class="author-name">Author: Tom</div>
					<div class="author-name">Published: Aug 23 2024</div>
				</div>
			</a>

			
			
			
			
			
			<div style="height: 70px" class="divider"></div>
			<h2>Clicky Buttons</h2>

			<p>I've given all the buttons a facelift with a unified style.</p>
			<p>Now when you click a button, you'll get that subtle "push" feeling. It's such a small change but hopefully will make the whole experience feel a bit better.</p>

			<h3>Before / after comparison</h3>
			
			<img src="../assets/post-image/2/old-new-full-page.png" alt="Quikweek v1.0" class="article-image">
			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Before / After — Can you spot the new buttons?
			</figcaption>
			
			<img src="../assets/post-image/2/nav-buttons.png" alt="Quikweek v1.0" class="article-image">
			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Before / After — Detail view of nav buttons
			</figcaption>
			
			<img src="../assets/post-image/2/post-button.png" alt="Quikweek v1.0" class="article-image">
			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Before / After — Detail view post link
			</figcaption>
			
			<img src="../assets/post-image/2/save-button.png" alt="Quikweek v1.0" class="article-image">
			<figcaption style="text-align: left; font-size: 0.7rem; color: var(--color-gray); margin-bottom: 28px;">
				Before / After — Detail view of Save button
			</figcaption>
			
			<div style="height: 70px" class="divider"></div>
			<h2>A Little Something Extra...</h2>

			<div class="explanation-container">
				<h2>While I was at it, I may have snuck in another little upgrade. But I don't want to spoil the surprise! </h2>
			</div>

			<p>Let's just say there's a new way to make Quikweek feel even more personal. </p>
			<p>I'm always looking for ways to improve Quikweek, so please let me know what you think of these changes. And if you happen to stumble upon anything interesting, I'd love to hear about it!</p>
			

			<h2>Happy planning!</h2>

		</div>

		<div style="height: 140px" class="divider"></div>

		



		<div class="footer">
			<div class="left-column">
				<div v-if="$route.path !== '/'">
					<button @click="$router.push('/')" class="article-link" style="background-color: #Ffffff; color: #353533;">Back to Home</button>
				</div>
			</div>
			<div class="right-column">
				<span>Quikweek v1.0.{{ daysPassed }}</span>
			</div>
		</div>




	</div>


</template>

<script>

import { useDateStore } from '@/stores/dateStore';

export default {
	setup() {
		const dateStore = useDateStore();
		return {
			daysPassed: dateStore.daysPassed,
		};
	},
	name: 'Post2',
};
</script>

